<template>
  <section class="section">
    <div class="container">
      <back-button to="LandingPage"/>
      <h1 class="has-text-centered is-size-2 pb-4">My files</h1>
      <template v-if="files.length > 0">
        <div class="columns is-multiline">
          <div class="column is-one-quarter is-full-mobile" v-for="file in files" :key="file.slug">
            <file :file="file" @delete="fetchFiles"/>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="notification is-info is-light has-text-centered">
          You have not yet uploaded a file.
        </div>
      </template>

      <upload-file @uploaded="fetchFiles"/>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import UploadFile from "../components/UploadFile";
import BackButton from "../components/BackButton";
import CopyInput from "../components/CopyInput";
import File from "../components/File";

export default {
  name: "MyFiles",
  components: {File, CopyInput, BackButton, UploadFile},
  computed: {
    ...mapGetters(['isLoggedIn', 'hasAccessToDomain', 'tokenReady'])
  },
  data() {
    return {
      files: []
    }
  },
  watch: {
    tokenReady(val) {
      if (val) {
        this.fetchFiles();
      }
    }
  },
  methods: {
    fetchFiles() {
      if (!this.isLoggedIn || !this.hasAccessToDomain) {
        this.$router.push({ name: 'Forbidden' });
        return;
      }

      this.$store.commit('setIsLoading', true);
      this.$http.get('/my-files').then(res => {
        this.files = res.data.data;
        this.$store.commit('setIsLoading', false);
      });
    }
  },
  created() {
    if (this.tokenReady) {
      this.fetchFiles();
    }
  }
}
</script>

<style scoped>

</style>
